<script>
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import Status from "@/helpers/general/status";

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        integration_id: Number,
        id: Number,
    },
    data() {
        return {
            integrationProductImport:null,
            webUrl: process.env.VUE_APP_WEB_URL,        
        }
    },
    components: {
        Modal
    },
    computed: {
        modalTilte(){
            return `Resultado de la sincronización - ${this.integrationProductImport?.integration?.name || '...'}`;
        },
        status(){
            return Status.getStatus({itemStatus: this.integrationProductImport?.process_status, type: 'integration_product_import_status'});
        }
    },
    methods: {
        async getData(){
            if (this.id) {
                const response = await api.get({
                    url: `integrations/${this.integration_id}/integration-product-imports/${this.id}`
                });
                this.integrationProductImport = response?.data?.data;
            }
        },
        shown() {
            this.getData();            
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        }
    }
};
</script>

<template>
    <div>
        <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Close'">
            <div class="row" v-if="integrationProductImport">
                <div class="col-md-4">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="card-title mb-4">Resumen</h4>

                            <table class="table table-hover mb-0 table-centered table-nowrap">
                                <tbody>
                                <tr>
                                    <th class="text-right">Fecha de inicio</th>
                                    <td>{{ integrationProductImport?.created_at }}</td>
                                </tr>
                                <tr>
                                    <th class="text-right">Fecha de finalización</th>
                                    <td>{{ integrationProductImport?.finished_at }}</td>
                                </tr>
                                <tr>
                                    <th class="text-right">Status</th>
                                    <td><span v-html="status"></span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="card-title mb-4">Detalle de la sincronización</h4>
                            <div class="alert alert-success text-center" v-if="!integrationProductImport?.errors">
                                La sincronización fue exitosa
                            </div>
                            <div class="custom-error-detail" v-if="integrationProductImport?.errors">
                                <div class="exception-summary">
                                    <div class="container">
                                        <div class="exception-message-wrapper">
                                            <h3 class="break-long-words exception-message">{{ integrationProductImport?.errors[0]?.message || '' }}</h3>
                                            <div class="exception-illustration hidden-xs-down"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="container mh-300">
                                    <div class="trace trace-as-html">
                                        <table class="trace-details table table-hover table-bordered mb-0 table-centered" v-for="(content, index) in integrationProductImport?.errors" :key="index">
                                            <thead class="trace-head"><tr><th>
                                                <h4 class="trace-class">
                                                    <span class="text-muted">({{(index+1)}}/{{integrationProductImport?.errors?.length}})</span>
                                                    <span class="exception_title"><span title="ErrorException">ErrorException</span></span>
                                                </h4>
                                                <p class="break-long-words trace-message">{{ content?.message || '' }}</p>
                                                <p class="break-long-words trace-message">{{ content?.custom_exception_message || '' }}</p>
                                            </th></tr></thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span class="block trace-file-path">in <span><strong>{{ content?.file || '' }}</strong> line {{ content?.line || '' }}</span></span>
                                                    </td>
                                                </tr>
                                                <tr v-for="(trace, subindex) in content?.trace" :key="subindex">
                                                    <td>
                                                        at <span class="trace-class">
                                                            <span>{{ trace?.class || '' }}</span>
                                                        </span>
                                                        <span class="trace-type">-></span>
                                                        <span class="trace-method">{{ trace?.function || '' }}</span>
                                                        (<span class="trace-arguments"></span>)
                                                        <span class="block trace-file-path">
                                                            in <span title=""><strong>{{ trace?.file || '' }}</strong> line {{ trace?.line || '' }}</span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>    
</template>