<script>
import PasswordField from "@/components/forms/password-field.vue";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PasswordField,
    },
    data() {
        return {      
        }
    },
    methods: {
        addRow(){
            this.value.extra_connection_data.push({
                field: null,
                value: null,
            });
        },
        deleteRow(key){
            this.value.extra_connection_data.splice(key, 1);
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-12">
            <p class="text-muted font-13">
                Para configurar la conexión con la API, completa únicamente los campos necesarios.
                Asegúrate de proporcionar toda la información requerida para establecer una conexión efectiva.
                En caso de que la API necesite parámetros adicionales específicos, tienes la opción de añadir campos personalizados según sea necesario.
                Esta flexibilidad te permite adaptar la configuración a las exigencias particulares de la API que deseas integrar.
            </p>
        </div>        
        <div class="form-group col-lg-6 col-sm-12">
            <label for="url">Url</label>
            <input id="url" v-model="value.url" type="text" class="form-control" placeholder="Ingresa la url de la API"
                :class="{ 'is-invalid': v.integration.url.$error }" />
        </div>
        <div class="form-group col-lg-6 col-sm-12">
            <label for="key">Llave</label>
            <input id="key" v-model="value.key" type="text" class="form-control" placeholder="Ingresa la llave de la API"
                :class="{ 'is-invalid': v.integration.key.$error }" />
        </div>
        <div class="form-group col-lg-6 col-sm-12">
            <label for="secret">Secreto</label>
            <PasswordField v-model="value.secret" :validationName="v.integration.secret" placeholder="Ingresa el secreto de la API"></PasswordField>
        </div>
        <div class="form-group col-lg-6 col-sm-12">
            <label for="username">Usuario</label>
            <input id="username" v-model="value.username" type="text" class="form-control" placeholder="Ingresa el nombre de usuario de la API"
                :class="{ 'is-invalid': v.integration.username.$error }" />
        </div>
        <div class="form-group col-lg-6 col-sm-12">
            <label for="password">Contraseña</label>
            <PasswordField v-model="value.password" :validationName="v.integration.password" placeholder="Ingresa la contraseña de la API"></PasswordField>
        </div>
        <div class="col-12">
            <hr class="my-4">
            <p class="text-muted font-13">
                Añade campos adicionales para configurar la API.
            </p>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Nombre del campo</th>
                        <th scope="col">Valor</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(field, i) in value?.extra_connection_data" :key="i" :set="vf = v.integration.extra_connection_data.$each[i]" class="">
                        <td>
                            <div class="form-group">
                                <input 
                                    type="text"
                                    v-model="field.field"
                                    class="form-control"
                                    :class="{ 'is-invalid': vf.field.$error }"
                                    placeholder="Nombre del campo"
                                />
                            </div>
                        </td>
                        <td>
                            <div class="form-group">
                                <input 
                                    type="text"
                                    v-model="field.value"
                                    class="form-control"
                                    :class="{ 'is-invalid': vf.value.$error }"
                                    placeholder="Valor del campo"
                                />
                            </div>
                        </td>
                        <td class="text-center">
                            <a
                                href="javascript:void(0);"
                                class="text-danger"
                                v-b-tooltip.hover
                                title="Eliminar"
                                @click="deleteRow(i)"
                                v-if="!field?.is_update"
                            >
                                <i class="mdi mdi-trash-can font-size-18"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="4" class="text-right">
                            <a
                                href="javascript:void(0);"
                                class="text-success"
                                @click="addRow()"
                            >
                                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar campo
                            </a>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>