<script>
import Vue from "vue";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import StoreForm from "./forms/integration-form.vue";
import AddButton from "@/components/buttons/add-button.vue";
import api from "@/helpers/apirest/api";
import IntegrationSyncProductsStatusVue from '@/components/helpers/integration-sync-products-status.vue';

/**
 * Stores Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    AddButton,
    StoreForm,
    IntegrationSyncProductsStatusVue
  },
  data() {
    return {
      modalId:'integration-form-modal',      
      autorefreshInterval: 5,
      intervalId: null,
      pageHeader:{
        title: "Integraciones",
        breadcrumbs: [
          {
            text: "Integraciones",
            href: "/integrations",
            active: true
          }
        ],
      },
      integrations: [],
      selectedItemId:null, 
      showSelectedItemId:null,
    };
  },
  methods: {
    showEditModal(item) {
      this.selectedItemId = item?.id || null;
      this.$bvModal.show(this.modalId);
    },
    onSuccess(){
      this.loadData();
    },
    async loadData() {
        try {            
            const response = await api.get({
                url:'integrations',
                params:{
                  with_trashed: 1
                }
            });

            const integrations = response?.data?.data;

            integrations.map(item => {
                item.soft_delete_status = (item?.deleted_at) ? 0 : 1;
            });

            this.integrations = integrations;
        } catch (error) {
          this.integrations = [];
        }
    },
    async syncProducts(integration){
        const result = await Vue.swal({
          title: "Sincronizar productos",
          icon: "info",
          html:  `Está seguro de sincronizar los productos de ${integration?.name}?`,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar',
        });

        if (result.value) {
          await api.post({
              url:`integrations/${integration?.id}/integration-product-imports`
          });

          this.loadData();
        }        
    },
    async softDeleteRow(item){
        if(!item?.deleted_at){
            await api.delete({
                url: 'integrations',
                id: item?.id
            });
        }else{
            await api.post({
                url:`integrations/${item?.id}/restore`
            });
        }

        this.loadData();
    },
    autorefresh() {
      const self = this;
      this.intervalId = setInterval(function() {
        self.loadData();
      }, this.autorefreshInterval * 1000);
    }
  },
  mounted() {
    this.loadData();
    this.autorefresh();
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <StoreForm :modal-id="modalId" :id="selectedItemId" @onSuccess="onSuccess"></StoreForm>    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body d-flex justify-content-between">
            <p class="text-muted font-13">Gestiona y sincroniza tus productos eficientemente desde otras plataformas a través de nuestras integraciones API.</p>
            <div class="text-right">
              <AddButton @click="showEditModal"></AddButton>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6" v-for="integration in integrations" :key="integration.id">
        <div class="card h-100" :class="{'opacity-5': integration?.deleted_at}">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-checkbox
                  v-model="integration.soft_delete_status"
                  switch class="mb-1"
                  value="1"
                  @change="softDeleteRow(integration)"
              ></b-form-checkbox>
              <b-dropdown right class="qlk-dropdown-sm" variant="white" size="sm">
                <template v-slot:button-content>
                  <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                </template>
                <b-dropdown-item @click="showEditModal(integration)">Editar</b-dropdown-item>
                <b-dropdown-item @click="syncProducts(integration)">Sincronizar productos</b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="text-center">
              <img :src="integration?.media?.url" alt class="avatar-sm mt-2 mb-4" />
              <div class="media-body">
                <h5 class="text-truncate">
                  <a href="#" class="text-dark">{{integration?.name}}</a>
                </h5>
                <p class="text-muted" v-if="!integration?.current_integration_product_import">
                  <i class="mdi mdi-calendar-clock mr-1" v-b-tooltip.hover title="Última sincronización"></i> {{integration?.last_sync_at || 'No sincronizado'}}
                </p>
                <IntegrationSyncProductsStatusVue
                  :current_integration_product_import="integration?.current_integration_product_import"
                ></IntegrationSyncProductsStatusVue>                
              </div>
            </div>
            <hr class="my-4" />
            <div class="row text-center">
              <div class="col-6">
                <p class="text-muted mb-2">Productos</p>
                <h5>{{ integration?.products_count }}</h5>
              </div>
              <div class="col-6">
                <p class="text-muted mb-2">Pedidos</p>
                <h5>{{ integration?.active_orders_count }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>