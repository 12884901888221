<script>
import Status from "@/helpers/general/status";
import IntegrationSyncProductResult from "@/views/pages/integrations/show/sync-product-result/integration-sync-product-result.vue";
export default {
  data() {
    return {
      sync_status: Status.getList('integration_product_import_status'),
      showModalId:null,
    };
  },
  props: {
    current_integration_product_import: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    IntegrationSyncProductResult
  },
  methods: {
    showViewModal() {
      this.$bvModal.show(this.showModalId);
    },
  },
  computed: {
    getStatus() {
      return Status.getStatus({itemStatus: this?.current_integration_product_import?.process_status || 0, type: 'integration_product_import_status'})
    }
  },
  mounted() {
    this.showModalId = `integration-product-imports-modal-detail-${this.current_integration_product_import?.id}`
  }
};
</script>

<template>
  <div>
    <IntegrationSyncProductResult :modal-id="showModalId" :id="current_integration_product_import?.id" :integration_id="current_integration_product_import?.integration_id"></IntegrationSyncProductResult>
    <p class="text-muted" v-if="current_integration_product_import?.process_status == 4">
      <i class="mdi mdi-calendar-clock mr-1" v-b-tooltip.hover title="Última sincronización"></i> {{ current_integration_product_import?.finished_at }}
    </p>
    <p class="text-muted" v-else-if="current_integration_product_import?.process_status == 5">      
      <i class="mdi mdi-alert mr-1 text-danger pointer" v-b-tooltip.hover title="Ver detalle de la sincronización" @click="showViewModal"></i>
      <span v-b-tooltip.hover title="La útlima sincronización falló">{{getStatus}}</span>
    </p>
    <p class="text-muted" v-else-if="current_integration_product_import" v-b-tooltip.hover title="Sincronización en progreso">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{getStatus}}
    </p>
    <p class="text-muted" v-else v-b-tooltip.hover title="No sincronizado">
      <i class="mdi mdi-calendar-clock mr-1" v-b-tooltip.hover title="Última sincronización"></i>
      No sincronizado
    </p>
    
  </div>
  
</template>